import React from "react";
import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom"; // Import NavLink from react-router-dom
import "./Servicestab.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Servicestab = () => {
  const ProductData = [
    {
      id: 1,
      imgSrc: process.env.PUBLIC_URL + "/assets/images/about/service-1.png",
      title: "Gears",
      detailsLink: "/services-category",
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/images/about/services-3.png",
      title: "Flanges",
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/images/about/tool-3.png",
      title: "Jig Fixture",
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/images/about/service-2.png",
      title: "Mechanical Job",
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/images/about/services-4.png",
      title: "Components",
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/images/about/tool-1.png",
      title: "Precision Tool Room Work",
    },
  ];

  return (
    <>
      <section className="serives-tabs">
        <Container>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col
                xxl={3}
                xl={3}
                lg={4}
                md={5}
                sm={10}
                xs={10}
                className="mx-auto"
              >
                <Nav variant="pills" className="justify-content-center">
                  <Nav.Item>
                    <NavLink
                      to="/services"
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                    >
                      Services
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      to="/export-services"
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                    >
                      Export Import
                    </NavLink>
                  </Nav.Item>
                </Nav>
              </Col>

              <div className="text-holder">
                <h2>
                  L Koumodaki Mfg. stands as a leading company in India,
                  specializing in the manufacturing of industrial gears and
                  parts. We are guided by the principle of "Excellence over
                  Quantity" at L Koumodaki Mfg.
                </h2>
                <p>
                  We are committed to delivering authentic, high-quality
                  products to our customers. We prioritize product excellence
                  and do not compromise on quality standards. Our
                  state-of-the-art imported machinery enables us to produce
                  world-class, dependable products. We utilize cutting-edge
                  equipment from renowned international brands such as WMW
                  Machinery Company, TOS, STANKO, GLEASON, DAEWOO PUMA, SYKES,
                  DNW, and more.
                </p>
              </div>
              <Row>
                <Col lg={10} md={12} className="mx-auto">
                  <Row>
                    {ProductData.map((card, index) => (
                      <Col lg={4} md={6} key={index}>
                        <Link to={card.detailsLink}>
                          <Card>
                            <Card.Img
                              variant="top"
                              className="product-img "
                              src={card.imgSrc}
                            />
                            <Card.Body>
                              <Card.Title>{card.title}</Card.Title>
                              <div className="icon-holder">
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  className="right-arrow"
                                />
                              </div>
                            </Card.Body>
                            <div className="overlay-color"></div>
                          </Card>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </>
  );
};

export default Servicestab;
