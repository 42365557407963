import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './OurProduct.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
const OurProduct = () => {
    const ProductData = [
        {
            imgSrc: process.env.PUBLIC_URL + "/assets/images/about/tool-1.png",
            title: "Precision Tool Room Work",

        },
        {
            imgSrc: process.env.PUBLIC_URL + "/assets/images/about/tool-2.png",
            title: "Components",

        },
        {
            imgSrc: process.env.PUBLIC_URL + "/assets/images/about/tool-3.png",
            title: "Jig Fixture",

        },
        {
            imgSrc: process.env.PUBLIC_URL + "/assets/images/about/tool-4.png",
            title: "Flanges",

        },

    ];
    return (
        <>
            <section className='our-product'>
                <Container>
                    <Row>
                        <div className='heading-holder text-center mb-md-5 mb-sm-5 mb-5'>
                            <h2 >Our Products</h2>
                        </div>
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={4}
                            className="mySwiper"
                            pagination={{
                                dynamicBullets: true,
                            }}
                            modules={[Pagination]}
                            breakpoints={{
                                0: { slidesPerView: 1, spaceBetween: 10 },
                                486: { slidesPerView: 1, spaceBetween: 10 },
                                567: { slidesPerView: 2, spaceBetween: 10 },
                                768: { slidesPerView: 2, spaceBetween: 30 },
                                992: { slidesPerView: 3, spaceBetween: 30 },
                                1024: { slidesPerView: 4, spaceBetween: 30 },
                            }}
                        >
                            {ProductData.map((card, index) => (
                                <SwiperSlide key={index}>
                                    <div className='product-card mb-5'>
                                        <div className='image-holder'>
                                            <img className="product-img " src={card.imgSrc} alt="product-img" />
                                        </div>
                                        <h3>{card.title}</h3>

                                    </div>

                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default OurProduct
