import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './WeAreLeader.css'
const WeAreLeader = () => {
    return (
        <>
            <section className='we-are-leader'>
                <Container>
                    <Row>
                        <div className='heading-holder text-center mb-md-5 mb-sm-3 mb-3'>
                            <h2>We Are <span className='our-text'>Leader in Industrial Market</span></h2>
                            <div className='d-flex justify-content-center'>
                                <div className='border-line me-2'></div>
                                <p>Precision & innovation drive our gear manufacturing excellence.</p>
                                <div className='border-line ms-2'></div>
                            </div>
                        </div>
                        <Col lg={6}>
                            <div className='text-holder'>
                                <h3>We are an established Turning Mechanical Engineering organization providing services in the PUNE.
                                    Our operations based in Bhosari Industrial Area with the motive of better service.</h3>
                                <h4>We are doing all types of the machining jobs, we are capable of machine shop & Laser cutting and Gear cutting.
                                    The workforce is highly skilled and properly guided by Engineers backed by well-organized management.
                                    We are working in the PUNE with our valuable clients in mechanical industries.</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since 1500s,
                                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                        </Col>
                        <Col lg={5} className='mx-auto'>
                            <Row>
                                <Col md={6} sm={6}>
                                    <div className='image-holder mb-3'>
                                        <img className="we-img" src={process.env.PUBLIC_URL + "/assets/images/about/image1.png"} alt="Logo" />
                                    </div>
                                </Col>
                                <Col md={6} sm={6}>
                                    <div className='image-holder mb-3'>
                                        <img className="we-img" src={process.env.PUBLIC_URL + "/assets/images/about/image-2.png"} alt="Logo" />
                                    </div>
                                </Col>
                                <Col md={6} sm={6}>
                                    <div className='image-holder mb-3'>
                                        <img className="we-img" src={process.env.PUBLIC_URL + "/assets/images/about/image-4.png"} alt="Logo" />
                                    </div>
                                </Col>
                                <Col md={6} sm={6}>
                                    <div className='image-holder mb-3'>
                                        <img className="we-img" src={process.env.PUBLIC_URL + "/assets/images/about/image-3.png"} alt="Logo" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <div className='text-holder'>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default WeAreLeader
