import React, { useState, useEffect } from 'react';
import './GallerImageModal.css';
import Modal from 'react-bootstrap/Modal';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const GallerImageModal = ({ show, onHide, galleryData, currentImageIndex, setCurrentImageIndex }) => {
    const [swiperInstance, setSwiperInstance] = useState(null);

    useEffect(() => {
        if (swiperInstance && typeof currentImageIndex === 'number') {
            swiperInstance.slideTo(currentImageIndex, 0);
        }
    }, [currentImageIndex, swiperInstance]);

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : galleryData.length - 1));
            } else if (direction === "next") {
                setCurrentImageIndex((prevIndex) => (prevIndex < galleryData.length - 1 ? prevIndex + 1 : 0));
            }
        }
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='gellery-modal'
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Swiper
                    slidesPerView={1}
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                    className="mySwiper"
                    initialSlide={currentImageIndex}
                >
                    {galleryData.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className='gallery-image'>
                                <img className="all-galley-img" src={process.env.PUBLIC_URL + item.imgSrc} alt="all-galley-img" />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="silder-btn">
                    <div
                        className="back-btn"
                        onClick={() => handleMainSliderClick("prev")}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="arrow-icon" />
                    </div>
                    <div
                        className="next-btn"
                        onClick={() => handleMainSliderClick("next")}
                    >
                          <FontAwesomeIcon icon={faArrowRight} className="arrow-icon"/>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default GallerImageModal;
