import React from 'react'
import QualityBanner from './QualityBanner/QualityBanner'
import GrapUp from './GrapUp/GrapUp'
import WeBelive from './WeBelive/WeBelive'
import MeetSection from '../home/MeetSection/MeetSection'

const QualityAssurance = () => {
    return (
        <>
        <div className='QualityAssurance'>

            <QualityBanner />
            <GrapUp />
            <WeBelive />
            <MeetSection />
        </div>
        </>
    )
}

export default QualityAssurance
