import React from 'react'
import FaqBanner from '../Faq/FaqBanner/FaqBanner'
import './TermsCondition.css'
import { Container, Row } from 'react-bootstrap'
const PrivacyPolicy = () => {
    return (
        <>
            <FaqBanner HeadingName={"Privacy Policy"} />
            <section className='terms-condition'>
                <Container>
                    <Row>
                        <div className='text-holder mb-3'>
                            <h2>Privacy Policy</h2>
                            <p className='mb-3'>1. We understand how important personal information is and we comply with the laws related to protection and handling of personal information.</p>
                            <p > 2. We will use customer information for the following purposes.</p>
                            <div className='ms-3 mb-4'>
                                <p> a) To respond to a customer inquiry</p>
                                <p>  b) Sending direct mail, catalogue, and E-mail information about our products and gear manufacturing services</p>
                                <p> c) Identifying customer needs and trends in order to improve our services.</p>
                            </div>
                            <p>  3. We manage the individual information provided by customers properly and do not disclose any information to third</p>
                            <div className='ms-3 mb-4'>
                                <p>  parties with the exception of the following cases.</p>
                                <p>   a) When we have the prior consent of the customer</p>
                                <p>  b) When providing to a third party such as a contracted affiliate, our related company or our overseas distributor in order
                                    v to accomplish the requested task.</p>
                            </div>
                            <p>In addition, for inquiries from overseas customers, there are times when our overseas distributors respond to the customers directly. </p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h2>Cookie Policy</h2>
                            <p>This website uses cookies.</p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h2>What is a cookie ?</h2>
                            <p>A cookie is a small text file that is sent from a website to your computer (or your other internet-enabled device) and stored there, when you access that website.
                                By using cookies, a website can obtain your browsing information (the number of visits to that website and which pages you have visited) of your computer or a device connected to the internet.
                                Note that the information obtained from cookies does not include data that can identify you.
                                You can also reject cookies in your browser settings.
                            </p>
                        </div>
                        <div className='text-holder mb-3'>
                            <h2>Purpose of using a cookie on this website</h2>
                            <p>By using a cookie to understand and analyze the customer access information, we can improve the convenience for users to use this website.<br />
                                For example, you can avoid having to repeatedly enter the same information every time you visit this website through the cookie.<br />
                                Besides, the cookie also allows us to conduct marketing research to develop products that are better suited to our customers' needs.<br />
                            </p>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PrivacyPolicy
