import React, { useEffect, useRef } from 'react'
import './CommanBanner.css'
import CommanButton from '../CommanButton/CommanButton'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const CommanBanner = ({ VideoPath, FirstHeading, SecondHeading, image, imgClassName, buttonName, thirdHeading }) => {



    return (
        <>

            <div className='video-holder'>
                <video className='video-class-grid' autoPlay loop muted>
                    <source src={VideoPath} className='video' type="video/mp4" />
                </video>
                <div className='heading-holder text-center'>
                    <div className='image-holder'>
                        <img src={image} className={imgClassName} alt="banner" />
                    </div>
                    <h1>{FirstHeading}</h1>
                    <h3>{thirdHeading}</h3>
                    <h2>{SecondHeading}</h2>
                </div>
                <div className={buttonName}>
                    <Link to={'/about-us'} >
                        <div>
                            <CommanButton className={"blue-btn"} text={"About Us"} />
                        </div>
                    </Link>
                    <Link to={'/contact-us'} >
                        <div>
                            <CommanButton className={"gray-btn"} text={"Contact Us"} />
                        </div>
                    </Link>
                </div>
                <div className='overlay-banner'></div>
            </div>

        </>
    )
}

export default CommanBanner
