import React from 'react'
import './OurServicesProcess.css'
import { Col, Container, Row } from 'react-bootstrap'

const OurServicesProcess = () => {
    const serviceSteps = [
        { imgSrc: "/assets/images/icon/choice.png", text: "Choose Product" },
        { imgSrc: "/assets/images/icon/OUTLINE.png", text: "Fill Form" },
        { imgSrc: "/assets/images/icon/credit-card.png", text: "Advance Payment" },
        { imgSrc: "/assets/images/icon/fast-delivery.png", text: "Fast Delivery" },
        { imgSrc: "/assets/images/icon/credit-card.png", text: "Full Payment On Delivery" },
    ];

    const orderStats = [
        { value: "9875", text: "Orders Exported", imgSrc: "/assets/images/icon/export.png" },
        { value: "348", text: "Permanent Clients", imgSrc: "/assets/images/icon/customer.png" },
        { value: "50", text: "Support Members", imgSrc: "/assets/images/icon/it-department.png" },
    ];

    return (
        <>
            <section className='our-services-process'>
                <Container>
                    <div className='heading-holder text-center'>
                        <h2>Our Service Process</h2>
                        <p>Our Easy Export Process for Your Easy Import</p>
                    </div>
                    <Row className='services-row'>
                        {serviceSteps.map((step, index) => (
                            <Col md={2} sm={6} xs={6} key={index}>
                                <div className='circle-holder'>
                                    <img className="choice-icon" src={process.env.PUBLIC_URL + step.imgSrc} alt={`step-${index}`} />
                                </div>
                                <div className='box-holder'>
                                    <p>{step.text}</p>
                                </div>
                                {index > 0 && (
                                    <div className='line-circle-drow'>
                                        <div className='circle-blue'></div>
                                        <div className='line-blue'></div>
                                        <div className='circle-blue'></div>
                                    </div>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <Row className=''>
                        <Col xxl={10} xl={10} lg={12} className='mx-auto'>
                            <Row>
                                {orderStats.map((stat, index) => (
                                    <Col md={4} key={index}>
                                        <div className='order-box '>
                                            <div className='order-text'>
                                                <p>{stat.value}</p>
                                                <span>{stat.text}</span>
                                            </div>
                                            <div className='image-icon'>
                                                <img className="order-icon" src={process.env.PUBLIC_URL + stat.imgSrc} alt={`stat-${index}`} />
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>

                    </Row>
                </Container>
            </section>
        </>
    )
}

export default OurServicesProcess
