import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import BreadcrumComponent from '../../CommanComponent/BreadcrumComponent/BreadcrumComponent'
import FaqBanner from '../../Faq/FaqBanner/FaqBanner';
import './BlogDetails.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faUser } from '@fortawesome/free-regular-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);
const BlogDetails = () => {
    const breadcrumbItems = [
        { text: "HOME", link: "/" },
        { text: "BLOGS", link: "/blog", },
        { text: "SILENCING THE SPIN: ADVANCED TECHNIQUES FOR GEARBOX NOISE REDUCTION", link: "/blog-details", className: "active" },
    ];
    return (
        <>
            <FaqBanner HeadingName={"Blog"} />
            <section className='blog-details'>
                <Container>
                    <Row>
                        <div className='mt-5'>
                            <BreadcrumComponent items={breadcrumbItems} />
                        </div>
                        <Col md={8}>
                            <div className='image-holder'>
                                <img className="details-img" src={process.env.PUBLIC_URL + "/assets/images/blog/blog-details.png"} alt={"details-img"} />
                            </div>
                        </Col>
                        <Col md={4} className='details-holder'>
                            {/* <div className='details-holder'> */}
                            <div className='text-holder'>
                                <h2>Silencing the Spin: Advanced Techniques for Gearbox Noise Reduction</h2>
                            </div>
                            <div className='d-flex'>
                                <div className='icon-holder'>
                                    <FontAwesomeIcon icon={faCalendar} className='fa-icon' /><span>Jun. 30, 2024</span>
                                </div>
                                <div className='icon-holder ms-5'>
                                    <FontAwesomeIcon icon={faUser} className='fa-icon' /><span>Admin</span>
                                </div>
                            </div>
                            <div className='view-details'>
                                <div className='icon-holder'>
                                    <FontAwesomeIcon icon="fa-solid fa-eye" className='fa-icon' /><span>Posts views: 589</span>
                                </div>
                            </div>
                            {/* </div> */}
                        </Col>
                        <Col md={12}>
                            <div className='paragraph-text mt-4'>
                                <p className='mb-5'>In the bustling world of machinery and mechanical systems, the symphony of moving parts often accompanies the efficiency of operation.
                                    However, for gear industry professionals, the continual hum of gearbox noise can pose a challenge. Thankfully,
                                    advancements in technology and engineering have paved the way for innovative noise reduction techniques,
                                    ensuring quieter operations without compromising performance. Let’s look into some of the key strategies employed in the quest for quieter gear systems.</p>
                                <h3>Advanced Gear Tooth Profiles: Precision in Motion</h3>
                                <p>At the heart of every gear system lies the intricate interplay of teeth meshing together to transmit power. Traditional gear tooth profiles, despite being effective, can
                                    generate significant noise during operation due to abrupt engagement and disengagement. However, advancements in gear design have led to the development of
                                    specialised tooth profiles aimed at minimising noise generation.
                                </p>
                                <p>
                                    One such innovation is the utilisation of helical gears, featuring angled teeth that gradually engage, resulting in smoother and quieter operation compared to their
                                    straight-toothed counterparts. Moreover, modifications to tooth geometry, such as profile shifting and optimisation of contact ratios, further contribute to noise
                                    reduction while maintaining efficiency.</p>
                                <h3>Vibration Dampening: Harmonising Motion</h3>
                                <p>Vibration is another culprit contributing to gearbox noise, often arising from dynamic forces within the system. To counteract these vibrations, engineers employ
                                    various dampening techniques that are designed to absorb or dissipate excess energy. One effective method involves the integration of vibration-dampening materials
                                    within gear assemblies. These materials, such as elastomers or specialised polymers, act as buffers, mitigating vibrations before they propagate throughout the
                                    system. Furthermore, the precision balancing of rotating components and the strategic placement of dampeners help minimise oscillations, resulting in quieter and
                                    more stable operation.</p>
                                <h3>Noise Insulation: Quieting the Quarters</h3>
                                <p>While optimising gear design & dampening vibrations can significantly reduce noise levels, additional measures may be necessary to achieve desired sound attenuation.
                                    Enter noise insulation, is a critical component in the arsenal of noise reduction techniques. Insulation materials, ranging from acoustic foams to composite barriers,
                                    are strategically applied to gearbox enclosures to absorb and block sound waves. By creating a barrier between the noisy internal components and the surrounding
                                    environment, insulation effectively reduces the transmission of noise, resulting in quieter operation and a more comfortable work environment for operators.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default BlogDetails
