import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './WhyShould.css';
import WHyShouldCard from './WHyShouldCard/WHyShouldCard';

const cardData = [
    {
        HeadingName: "OUR VALUES",
        image: process.env.PUBLIC_URL + "/assets/images/icon/values.png",
        SecondText: "Quality Comes First",
        ParagraphText: "We are working in the PUNE with our valuable clients in civil and mechanical, power plants industries"
    },
    {
        HeadingName: "OUR MISSION",
        image: process.env.PUBLIC_URL + "/assets/images/icon/mission.png",
        SecondText: "Innovation in the Field of Engineering",
        ParagraphText: "Our aim to deliver predictable, sustainable, profitable engineering services & manufacturing. Our strong management and leading technical performance to meet the demands of your technical engineering needs."
    },
    {
        HeadingName: "OUR VISION",
        image: process.env.PUBLIC_URL + "/assets/images/icon/vision.png",
        SecondText: "We Aim for the Top",
        ParagraphText: "We will inspire customer enthusiasm through operational engineering excellence, Our focus is always on our customer having unbending commitment to quality, we have highly flexible responsive team."
    }
];

const WhyShould = () => {
    return (
        <section className='why-should '>
            <Container>
                <Row>
                    <div className='main-heading text-center mb-lg-5 mb-md-3 mb-sm-3 mb-3'>
                        <h2>Why You Should Choose Us?</h2>
                    </div>
                    {cardData.map((card, index) => (
                        <Col lg={4} md={6} key={index} className='mb-lg-0 mb-md-3 mb-sm-3 mb-3'>

                            <WHyShouldCard
                                className={index === 1 ? 'second-card ' : ''}
                                HeadingName={card.HeadingName}
                                image={card.image}
                                SecondText={card.SecondText}
                                ParagraphText={card.ParagraphText}
                            />

                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}

export default WhyShould;
