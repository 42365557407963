import React from "react";
import { Container, Row } from "react-bootstrap";
import "./OurProfessionalism.css";
const OurProfessionalism = () => {
  return (
    <>
      <section className="our-professionalism">
        <Container>
          <Row>
            <div className="heading-holder text-center">
              <h2>Our Professionalism</h2>
              <p>
                At KAUMODAKI Mfg., PUNE we complete our promise to save client's
                time and money. We provides professional technical solutions to
                its clients with best services and materials, we never
                compromise on quality. Our team is highly efficient & qualified
                with professional approach to solve any technical issues.
              </p>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default OurProfessionalism;
