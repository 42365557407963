import React from "react";
import "./Manufacturing_Unit.css";
import Manufacturing_Unit_Card from "./Manufacturing_Unit_Card/Manufacturing_Unit_Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

function Manufacturing_Unit() {
  const cardsData = [
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/images/home/Manufacturing/image-1.png",
      title: "VMC Machine",
      description: "A VMC machine is a versatile tool used in precision manufacturing, offering efficient milling, drilling, & cutting operations. It has a crucial role in achieving high-precision components across various industries.",
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/images/home/Manufacturing/image-2.png",
      title: "CNC Machine",
      description: "A CMC machine specializes in machining composite materials, essential for aerospace & automotive industries due to their lightweight and durable properties. It enables precise shaping and cutting, crucial for manufacturing advanced components.",
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/images/home/Manufacturing/image-3.png",
      title: "Redial Drill Machine",
      description: "It is a versatile tool used for drilling holes in large workpieces with adjustable radial arm movement, enhancing flexibility and precision in industrial operations.",
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/images/home/Manufacturing/image-4.png",
      title: "Turrent Milling Machine",
      description: "Turret milling machines are renowned for their versatility in performing various milling operations with a rotating turret holding multiple cutting tools, making them ideal for complex and precise machining tasks.",
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/images/home/Manufacturing/image-5.png",
      title: "Milling Machine",
      description: "A VMC machine is a versatile tool used in precision manufacturing, offering efficient milling, drilling, & cutting operations. It has a crucial role in achieving high-precision components across various industries.",
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/images/home/Manufacturing/image-6.png",
      title: "Mechanical Press Machine",
      description: "A CMC machine specializes in machining composite materials, essential for aerospace & automotive industries due to their lightweight and durable properties. It enables precise shaping and cutting, crucial for manufacturing advanced components.",
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/images/home/Manufacturing/image-7.png",
      title: "SPM Bending Machine",
      description: "It is a versatile tool used for drilling holes in large workpieces with adjustable radial arm movement, enhancing flexibility and precision in industrial operations.",
    },
    {
      imgSrc: process.env.PUBLIC_URL + "/assets/images/home/Manufacturing/image-8.png",
      title: "Lathe Machine",
      description: "Turret milling machines are renowned for their versatility in performing various milling operations with a rotating turret holding multiple cutting tools, making them ideal for complex and precise machining tasks.",
    },
  ];

  return (
    <div className="Manufacturing_Unit">
      <div className="container">
        <div className='heading-holder text-center mb-md-5 mb-sm-3 mb-3'>
          <h2 className="our-text">Our Manufacturing Units</h2>
        </div>
        <div className="Manufacturing_Unit_holder">
          <Swiper
            spaceBetween={30}
            slidesPerView={4}
            className="mySwiper Manufacturing_Unit_swiper"
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            breakpoints={{
              0: { slidesPerView: 1, spaceBetween: 10 },

              425: { slidesPerView: 2, spaceBetween: 10 },

              676: { slidesPerView: 2, spaceBetween: 10 },
              768: { slidesPerView: 2, spaceBetween: 20 },
              992: { slidesPerView: 2, spaceBetween: 20 },
              1024: { slidesPerView: 4, spaceBetween: 20 },
            }}
          >
            {cardsData.map((card, index) => (
              <SwiperSlide key={index}>
                <Manufacturing_Unit_Card
                  imgSrc={card.imgSrc}
                  title={card.title}
                  description={card.description}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Manufacturing_Unit;
