import React from 'react'
import CommanBanner from '../../CommanComponent/CommanBanner/CommanBanner'

const ExportServicesBanner = () => {
    return (
        <>
            <section className="About-banner">
                <div>
                    <CommanBanner
                        VideoPath={process.env.PUBLIC_URL + "/assets/video/servicebannsecond.mp4"}
                        imgClassName="d-none"
                        FirstHeading={"Services"}
                        SecondHeading="Always On Demand"
                        buttonName="d-none"
                    />
                </div>
            </section>
        </>
    )
}

export default ExportServicesBanner
