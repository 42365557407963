import React from 'react'
import AboutUsBanner from './AboutUsBanner/AboutUsBanner'
import WeAreLeader from './WeAreLeader/WeAreLeader'
import CounterSection from '../home/CounterSection/CounterSection'
import OurProfessionalism from '../home/OurProfessionalism/OurProfessionalism'
import LogoSlider from '../home/LogoSlider/LogoSlider'
import OurProduct from './OurProduct/OurProduct'
import MeetSection from '../home/MeetSection/MeetSection'
import WhatOurClient from '../home/WhatOurClient/WhatOurClient'

function About_Us() {
  return (
    <>
      <div className='About_Us'>
        <AboutUsBanner />
        <WeAreLeader />
        <CounterSection />
        <OurProfessionalism />
        <LogoSlider />
        <OurProduct />
        <MeetSection />
        <WhatOurClient />
      </div>
    </>
  )
}

export default About_Us