import React from "react";
import "./WeAreHome.css";
import { Col, Container, Row } from "react-bootstrap";
import CommanButton from "../../CommanComponent/CommanButton/CommanButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
const WeAreHome = () => {
  return (
    <>
      <section className="we-are-home">
        <Container>
          <Row>
            <Col md={6}>
              <div className="heading-holder">
                <h2>We Are </h2>
                <h3>Leader in Industrial Market</h3>
                <h4>
                  We are an established Turning Mechanical Engineering
                  organization providing services in the PUNE. Our operations
                  based in Bhosari Industrial Area with the motive of better
                  service.
                </h4>
                <p>
                  We are doing all types of the machining jobs, we are capable
                  of machine shop & Laser cutting and Gear cutting. The
                  workforce is highly skilled and properly guided by Engineers
                  backed by well-organized management. We are working in the
                  PUNE with our valuable clients in mechanical industries.
                </p>
              </div>
              <div className="d-flex social-img-logo">
                <div className="heading-holder">
                  <h4>
                    Precision & innovation drive our
                    <br /> gear manufacturing excellence.
                  </h4>
                </div>
                <div className="quatation-img ms-auto">
                  <img
                    className="queta-img me-2"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/quetation-img.png"
                    }
                    alt="queta-img"
                  />
                  <img
                    className="queta-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/quetation-img.png"
                    }
                    alt="queta-img"
                  />
                </div>
              </div>
              <div className="border-line my-3"></div>
              <div className="d-flex social-img-logo ">
                <Link to={"/about-us"}>
                  {" "}
                  <div className="button-holder">
                    <CommanButton className={"blue-btn "} text={"About Us"} />
                  </div>
                </Link>
                <div className="d-flex ms-auto social-icon">
                  <div className="border-icon text-center">
                    <Link to={"https://www.facebook.com/"} target="_black">
                      <FontAwesomeIcon icon={faFacebookF} className="fb-icon" />
                    </Link>
                  </div>
                  <div className="border-icon text-center">
                    <Link to={"https://in.linkedin.com/"} target="_black">
                      <FontAwesomeIcon
                        icon={faLinkedinIn}
                        className="fb-icon"
                      />
                    </Link>
                  </div>
                  <div className="border-icon text-center">
                    <Link to={"https://www.instagram.com/"} target="_black">
                      <FontAwesomeIcon icon={faInstagram} className="fb-icon" />
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={5} className="mx-auto">
              <div className="image-holder text-center">
                <img
                  className="builder-img "
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/home/builder-img.png"
                  }
                  alt="builder-img"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default WeAreHome;
