import React from "react";
import "./Footer.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <>
      <section className="footer">
        <Container>
          <div className="row">
            <div className="col-12  ">
              <Link to={"/"}>
                <div className="text-md-center text-sm-center text-center">
                  <img
                    className="header-logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/logo/footer-logo.png"
                    }
                    alt="Logo"
                  />
                </div>
              </Link>
            </div>
            <div className="col-12 ">
              <Nav
                defaultActiveKey="/"
                className="justify-content-lg-center justify-content-md-center justify-content-sm-center justify-content-start all-nav-link"
              >
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/">
                    Home
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/about-us">
                    About Us
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/services">
                    Services
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/contact-us">
                    Contact Us
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/faq">
                    FAQs
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/terms-condition">
                    Terms & Conditions
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className="nav-link" as={Link} to="/privacy-policy">
                    Privacy Policy
                  </NavLink>
                </Nav.Item>
              </Nav>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="d-flex location-adddd">
                <div className="icon-holder">
                  <img
                    className="location-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/calendar.png"
                    }
                    alt="Logo-1"
                  />
                </div>
                <div className="text-holder ps-2">
                  <p className="me-2">Monday - Sunday</p>
                  <p className="me-2"> 10:00 am - 06:00 pm</p>
                  <p className="me-2"> Thursday Closed</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="d-flex location-adddd">
                <div className="icon-holder">
                  <img
                    className="location-icon"
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icon/email.png"
                    }
                    alt="Logo-1"
                  />
                  <div>
                    <img
                      className="location-icon mt-3"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icon/material-call.png"
                      }
                      alt="Logo-1"
                    />
                  </div>
                </div>

                <div className="text-holder ps-2">
                  <p className="me-2">info@lkaumodaki.com</p>
                  <div className="mt-4">
                    <p className="me-2 ">+91 9762331812</p>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex">
                <div className="icon-holder">
                  <img className="location-icon" src={process.env.PUBLIC_URL + "/assets/icon/material-call.png"} alt="Logo-1" />
                </div>
                <div className="text-holder">
                  <p className="me-2">+91 9762331812</p>
                </div> */}
              {/* </div> */}
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="d-flex location-adddd">
                <div className="icon-holder">
                  <img
                    className="location-icon"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/location-on.png"
                    }
                    alt="Logo-1"
                  />
                </div>
                <div className="text-holder ps-2">
                  <p className="me-2">
                    Sr. No. 118, Tingare Nagar, Godown Chowk, Pune Nashik
                    Highway, Bhosari MIDC Pune 411039
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="copyright-holder text-center">
                <p>
                  © 2024 L KAUMODAKI. All Rights Reserved.
                  <Link to="https://profcymaglobal.com/" target="___blank">
                    Developed by Profcyma.
                  </Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Footer;
