import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import TechnologyCard from './TechnologyCard/TechnologyCard';
const LatestTechnology = () => {
  const TechnologyCardDetails = [
    {
      id: 1,
      content: "Cost Efficient",
      ParagraphText: "Maximizing cost efficiency is paramount in our strategy.",
      image: process.env.PUBLIC_URL + "/assets/images/icon/cost-efficient.png",
      detailsLink: "",
    },
    {
      id: 2,
      content: "Latest Technology",
      ParagraphText: "Adopting latest technology is essential for staying ahead.",
      image: process.env.PUBLIC_URL + "/assets/images/icon/technology.png",
      detailsLink: "",
    },
    {
      id: 3,
      content: "Industry Solution",
      ParagraphText: "Tailored industry solutions designed for optimal efficiency.",
      image: process.env.PUBLIC_URL + "/assets/images/icon/industry-solution.png",
      detailsLink: "",
    },
  ];
  return (
    <>
      <section className='latest-technology'>
        <Container>
          <Row>
            <Swiper
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                486: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1400: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1900: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {TechnologyCardDetails.map((item, index) => (
                <SwiperSlide key={index}>
                  <Link to={item.detailsLink}>
                    <TechnologyCard
                      className="main-section"
                      content={item.content}
                      ParagraphText={<span dangerouslySetInnerHTML={{ __html: item.ParagraphText }} />}
                      image={item.image}

                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default LatestTechnology
