import React from 'react'
import './WeBelive.css'
import { Col, Container, Row } from 'react-bootstrap'
const WeBelive = () => {
    return (
        <>
            <section className='we-belive'>
                <Container>
                    <Row>
                        <Col lg={7} md={6}>
                            <div className='d-flex social-img-logo'>
                                <div className='quatation-img '>
                                    <img className="comma-img me-2" src={process.env.PUBLIC_URL + "/assets/images/icon/Union.png"} alt="queta-img" />
                                    <img className="comma-img" src={process.env.PUBLIC_URL + "/assets/images/icon/Union.png"} alt="queta-img" />
                                </div>
                                <div className='heading-holder  '>
                                    <h3>SINCE WE BELIEVE</h3>
                                    <h4>QUALITY IS WHAT</h4>
                                    <h5>KEEPS US IN BUSINESS”</h5>
                                </div>
                            </div>
                        </Col>
                        <Col lg={5} md={6}>
                        <div className='image-holder'>
                        <img className="certificate-img " src={process.env.PUBLIC_URL + "/assets/images/quality/certificate.png"} />   
                        </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default WeBelive
