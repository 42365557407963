import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "./EnquiryModal.css"; // Import the custom CSS
import CommanButton from "../../CommanButton/CommanButton";
import Select from "react-select"; // Import react-select
import SuccessLottey from "../../SuccessLottey/SuccessLottey";
const EnquiryModal = (props) => {
  const [phone, setPhone] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
      window.location.href = "/";
    }, 3000);
  };

  const countryOptions = [
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
    { value: "three", label: "Three" },
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "#F3F3F3",
      borderRadius: "5px",
      fontSize: "14px",
      border: "none",
      height: "40px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "none",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d" /* Bootstrap's default placeholder color */,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#495057" /* Bootstrap's default input text color */,
    }),
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="enqiry-modal contact-us"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Connect with Us
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupFullName">
              <Form.Control type="text" placeholder="Enter Full Name*" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Control type="email" placeholder="Enter Email" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPhone">
              <PhoneInput
                country={"in"}
                enableSearch={true}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                containerClass="phone-input-container" // Custom class for the container
                inputClass="phone-input-field" // Custom class for the input field
                buttonClass="phone-input-button" // Custom class for the button
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupCountry">
              <Select
                options={countryOptions}
                value={selectedCountry}
                onChange={setSelectedCountry}
                placeholder="Select Country*"
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupCity">
              <Form.Control type="text" placeholder="Enter City*" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupMessage">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Write your message here"
                className="textarea-class"
              />
            </Form.Group>
            {!showSuccessMessage && (
              <div className="button-holder text-center my-5">
                <CommanButton
                  className={"blue-btn"}
                  text={"Submit"}
                  onClick={handleSubmit}
                />
              </div>
            )}
          </Form>
          {showSuccessMessage && (
            <div className="sent-message-text my-5 justify-content-center d-flex">
              <SuccessLottey />
              <p>Enquiry Generated Successfully</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EnquiryModal;
