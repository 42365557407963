import React from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import './CategoryProduct.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommanProductCard from '../../CommanComponent/CommanProductCard/CommanProductCard';
import { Link } from 'react-router-dom';

const CategoryProduct = ({ productName }) => {
    const allProducts = [
        {
            id: 1,
            name: 'Bevel Gear',
            imgSrc: '/assets/images/services/Gear1.png',
            detailsLink: '/product-details', // Example link for Bevel Gear
        },
        {
            id: 2,
            name: 'Pinion Gear',
            imgSrc: '/assets/images/services/gear2.png',
            detailsLink: '/product-details', // Example link for Pinion Gear
        },
        {
            id: 3,
            name: 'Sprocket Gear',
            imgSrc: '/assets/images/services/gaer4.png',
            detailsLink: '/product-details', // Example link for Sprocket Gear
        },
        {
            id: 4,
            name: 'Crown / Spiral Bevel Gear',
            imgSrc: '/assets/images/services/gear3.png',
            detailsLink: '/product-details', // Example link for Crown / Spiral Bevel Gear
        },
        {
            id: 5,
            name: 'Another Product',
            imgSrc: '/assets/images/services/gaer4.png',
            detailsLink: '/product-details', // Example link for Another Product
        },
        {
            id: 6,
            name: 'Another Product',
            imgSrc: '/assets/images/services/gear2.png',
            detailsLink: '/product-details', // Example link for Another Product
        },
    ];

    return (
        <div className='category-product'>
            <Row>
                <Col md={8}>
                    <div className='text-holder'>
                        <h3>{productName}</h3>
                    </div>
                </Col>
                <Col md={4}>
                    <Form>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="Enter keywords"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                            />
                            <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon icon="fa-solid fa-search" className='search-icon' />
                            </InputGroup.Text>
                        </InputGroup>
                    </Form>
                </Col>
                {allProducts.map((product, index) => (
                    <Col lg={4} md={6} key={index}>
                        <Link to={"/product-details"}>
                            <CommanProductCard
                                productName={product.name}
                                image={product.imgSrc}
                                detailsLink={product.detailsLink}
                            />
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default CategoryProduct;
