import React, { useEffect, useRef } from "react";
import "./Banner.css";
import { Row } from "react-bootstrap";
import CommanBanner from "../../CommanComponent/CommanBanner/CommanBanner";
function Banner() {

  return (
    <section className="home-banner">
      <div>
        <CommanBanner
          VideoPath={process.env.PUBLIC_URL + "/assets/video/home-video.mp4"}
          image={process.env.PUBLIC_URL + "/assets/images/home/logo-name.png"}
          imgClassName="banner-img"
          SecondHeading="Your Solution Partner in Automation"
          buttonName="button-holder d-flex justify-content-center"
        />
      </div>
    </section>
  );
}

export default Banner;
