import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(fas);

const Header = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");

  useEffect(() => {
    const path = location.pathname.substring(1);

    if (
      path === "export-services" ||
      path === "services-category" ||
      path === "product-details"
    ) {
      setActiveLink("services");
    } else {
      setActiveLink(path || "home");
    }
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  return (
    <>
      <section className="main-header">
        <div className="top-header">
          <Container>
            <Row>
              <Col md={3} sm={3} xs={4}>
                <div className="d-flex social-icon">
                  <div className="border-icon text-center">
                    <FontAwesomeIcon icon={faPhone} className="fb-icon" />
                  </div>
                  <span>+91 9762331812</span>
                </div>
              </Col>
              <Col md={6} sm={6} xs={4}>
                <div className="image-holder my-2 text-center" to="/">
                  <img
                    className="headlogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/images/logo/logo.png"
                    }
                    alt="Logo"
                  />
                </div>
              </Col>
              <Col md={3} sm={3} xs={4}>
                <div className="d-flex justify-content-center social-icon">
                  <Link to={"https://www.facebook.com/"} target="_black">
                    <div className="border-icon text-center">
                      <FontAwesomeIcon icon={faFacebookF} className="fb-icon" />
                    </div>
                  </Link>
                  <Link to={"https://in.linkedin.com/"} target="_black">
                    <div className="border-icon text-center">
                      <FontAwesomeIcon
                        icon={faLinkedinIn}
                        className="fb-icon"
                      />
                    </div>
                  </Link>
                  <Link to={"https://www.instagram.com/"} target="_black">
                    <div className="border-icon text-center">
                      <FontAwesomeIcon icon={faInstagram} className="fb-icon" />
                    </div>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="second-header">
          <Container>
            <Navbar expand="lg">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-auto">
                  <Nav.Link
                    as={Link}
                    to="/"
                    className={`nav-link-with-borders ${
                      activeLink === "home" ? "active" : ""
                    }`}
                    onClick={() => handleNavItemClick("home")}
                  >
                    Home
                    <div className="">
                      <div className="white-border"></div>
                    </div>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/about-us"
                    className={`nav-link-with-borders ${
                      activeLink === "about-us" ? "active" : ""
                    }`}
                    onClick={() => handleNavItemClick("about-us")}
                  >
                    About Us
                    <div className="">
                      <div className="white-border"></div>
                    </div>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/services"
                    className={`nav-link-with-borders ${
                      activeLink === "services" ? "active" : ""
                    }`}
                    onClick={() => handleNavItemClick("services")}
                  >
                    Services
                    <div className="">
                      <div className="white-border"></div>
                    </div>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/quality-assurance"
                    className={`nav-link-with-borders ${
                      activeLink === "quality-assurance" ? "active" : ""
                    }`}
                    onClick={() => handleNavItemClick("quality-assurance")}
                  >
                    Quality Assurance
                    <div className="">
                      <div className="white-border"></div>
                    </div>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/contact-us"
                    className={`nav-link-with-borders ${
                      activeLink === "contact-us" ? "active" : ""
                    }`}
                    onClick={() => handleNavItemClick("contact-us")}
                  >
                    Contact Us
                    <div className="">
                      <div className="white-border"></div>
                    </div>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/gallery"
                    className={`nav-link-with-borders ${
                      activeLink === "gallery" ? "active" : ""
                    }`}
                    onClick={() => handleNavItemClick("gallery")}
                  >
                    Gallery
                    <div className="">
                      <div className="white-border"></div>
                    </div>
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/blog"
                    className={`nav-link-with-borders ${
                      activeLink === "blog" ? "active" : ""
                    }`}
                    onClick={() => handleNavItemClick("blog")}
                  >
                    Blog
                    <div className="">
                      <div className="white-border"></div>
                    </div>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Header;
