import React, { useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import './WhatOurClient.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
// import { Pagination } from "swiper/modules";

const WhatOurClient = () => {
    const [swiperInstance, setSwiperInstance] = useState(null);

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const ClientSlider = [
        {
            NameText: "Sumit Kale",
            LocationText: "Pune, Maharastra",
            paragraphText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
        {
            NameText: "Roopak J.",
            LocationText: "Pune, Maharastra",
            paragraphText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
        {
            NameText: "Sumit Kale",
            LocationText: "Pune, Maharastra",
            paragraphText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
        {
            NameText: "Roopak J.",
            LocationText: "Pune, Maharastra",
            paragraphText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
    ];

    return (
        <>
            <section className='our-client-say'>
                <Container>
                    <Row>
                        <div className='heading-holder text-center mb-5'>
                            <h2>What Our Clients Say!</h2>
                        </div>

                        <Swiper
                            breakpoints={{
                                0: { slidesPerView: 1, spaceBetween: 10 },
                                486: { slidesPerView: 1, spaceBetween: 20 },
                                768: { slidesPerView: 2, spaceBetween: 20 },
                                992: { slidesPerView: 2, spaceBetween: 20 },
                                1200: { slidesPerView: 2, spaceBetween: 30 },
                                1400: { slidesPerView: 2, spaceBetween: 30 },
                            }}
                            pagination={{ dynamicBullets: true }}
                            // modules={[Pagination]}
                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                            className="mySwiper"
                        >
                            {ClientSlider.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className='client-card mb-5'>
                                        <div className='name-holder'>
                                            <h2>{item.NameText}</h2>
                                            <p>{item.LocationText}</p>
                                        </div>
                                        <div className='contect-text'>
                                            <p>{item.paragraphText}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="silder-btn">
                            <div
                                className="back-btn"
                                onClick={() => handleMainSliderClick("prev")}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} className="arrow-icon" />
                            </div>
                            <div
                                className="next-btn"
                                onClick={() => handleMainSliderClick("next")}
                            >
                                <FontAwesomeIcon icon={faArrowRight} className="arrow-icon"/>
                            </div>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default WhatOurClient
