import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './CommanButton.css'
import { Button } from 'react-bootstrap';
const CommanButton = ({ onClick,
    className,
    margin,
    text,
    border,
    background,
    borderRadius,
    fontSize,
    color,
    fontWeight,
    padding,
    icon,
    showHeart, }) => {
    const buttonStyle = {
        background,
        borderRadius,
        border,
        fontSize,
        fontWeight,
        color,
        padding,
        margin,
        className,
    };
    return (
        <>
            <Button   type="button" className={className} onClick={onClick}>
                {icon && (
                    <FontAwesomeIcon
                        icon={icon}
                        className={`heart-icon me-2 ₹{showHeart ? "heart-icon  active" : ""}`}
                    />
                )}
                {text}
            </Button>
        </>
    )
}

export default CommanButton
