import React, { useState } from 'react';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import CategoryTabSection from './CategoryTabSection/CategoryTabSection';
import FaqBanner from '../Faq/FaqBanner/FaqBanner';
import BreadcrumComponent from '../CommanComponent/BreadcrumComponent/BreadcrumComponent';
import CategoryProduct from './CategoryProduct/CategoryProduct';

const ServicesCategory = () => {
    const [selectedTab, setSelectedTab] = useState("first");

    const handleTabSelect = (eventKey) => {
        setSelectedTab(eventKey);
    };
    const breadcrumbItems = [
        { text: "HOME", link: "/" },
        { text: "SERVICES", link: "/services", },
        { text: "GEARS", link: "/Gears", className: "active" },

    ];
    return (
        <>
            <FaqBanner HeadingName={"Gears"} />
            <section className='services-category'>
                <Container>
                    <div className='my-5'>
                        <BreadcrumComponent items={breadcrumbItems} />
                    </div>
                    <Tab.Container id="left-tabs-example" activeKey={selectedTab} onSelect={handleTabSelect}>
                        <Row>
                            <Col lg={3}>
                                <CategoryTabSection onSelect={handleTabSelect} />
                            </Col>
                            <Col lg={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <CategoryProduct
                                            productName={"Gears"}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <CategoryProduct
                                            productName={"Flanges"}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <CategoryProduct
                                            productName={"Brass Components"}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fifth">
                                        <CategoryProduct
                                            productName={"Stainless Steel Components"}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </section>
        </>
    );
}

export default ServicesCategory;
