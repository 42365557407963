import React from 'react';
import './Blog.css';
import FaqBanner from '../Faq/FaqBanner/FaqBanner';
import Pagination from 'react-bootstrap/Pagination';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faUser } from '@fortawesome/free-regular-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
library.add(fas);

const Blog = () => {
    const blogData = [
        {
            imgSrc: "/assets/images/blog/blog1.png",
            title: "Silencing the Spin: Advanced Techniques for Gearbox Noise Reduction",
            description: "In the bustling world of machinery & mechanical systems, the symphony of moving parts often accompanies the efficiency of operation…."
        },
        {
            imgSrc: "/assets/images/blog/blog2.png",
            title: "Know the Advanced Materials in the Gear Manufacturing Sector",
            description: "In the dynamic domain of engineering, the quest for lighter yet stronger materials is perpetual. This pursuit has led to…"
        },
        {
            imgSrc: "/assets/images/blog/blog3.png",
            title: "Gear Grinding Machine Evolution in Modern Manufacturing",
            description: "In today’s industry, gear grinding machines have witnessed notable advancements, enhancing the precision and efficiency of gear production..."
        },
        {
            imgSrc: "/assets/images/blog/blog4.png",
            title: "What Keeps Your Gears Grinding? 5+ Gearbox Maintenance Tips",
            description: "Marketing Specialist, Houston Pump and Gear Gearboxes and their components are integral to the industrial equipment that makes the world…"
        },
        {
            imgSrc: "/assets/images/blog/blog2.png",
            title: "Know the Advanced Materials in the Gear Manufacturing Sector",
            description: "In the dynamic domain of engineering, the quest for lighter yet stronger materials is perpetual. This pursuit has led to…"
        },
        {
            imgSrc: "/assets/images/blog/blog1.png",
            title: "Silencing the Spin: Advanced Techniques for Gearbox Noise Reduction",
            description: "In today’s industry, gear grinding machines have witnessed notable advancements, enhancing the precision and efficiency of gear production..."
        },
        {
            imgSrc: "/assets/images/blog/blog1.png",
            title: "Silencing the Spin: Advanced Techniques for Gearbox Noise Reduction",
            description: "In the bustling world of machinery & mechanical systems, the symphony of moving parts often accompanies the efficiency of operation…."
        },
        {
            imgSrc: "/assets/images/blog/blog3.png",
            title: "Gear Grinding Machine Evolution in Modern Manufacturing",
            description: "In today’s industry, gear grinding machines have witnessed notable advancements, enhancing the precision and efficiency of gear production..."
        },
        {
            imgSrc: "/assets/images/blog/blog2.png",
            title: "Know the Advanced Materials in the Gear Manufacturing Sector",
            description: "In the dynamic domain of engineering, the quest for lighter yet stronger materials is perpetual. This pursuit has led to…"
        },
    ];

    const recentBlogs = [
        {
            imgSrc: "/assets/images/blog/blog1.png",
            date: "Jun. 30, 2024",
            author: "Admin",
            title: "Silencing the Spin: Advanced Techniques for Gearbox Noise Reduction",
            description: "In the bustling world of machinery and mechanical systems, the symphony of moving parts often accompanies the..."
        },
        {
            imgSrc: "/assets/images/blog/blog2.png",
            date: "Jun. 30, 2024",
            author: "Admin",
            title: "Know the Advanced Materials in the Gear Manufacturing Sector",
            description: "In the dynamic domain of engineering, the quest for lighter yet stronger materials is perpetual. This pursuit has led to…"
        },
        {
            imgSrc: "/assets/images/blog/blog3.png",
            date: "Jun. 30, 2024",
            author: "Admin",
            title: "Gear Grinding Machine Evolution in Modern Manufacturing",
            description: "In today’s industry, gear grinding machines have witnessed notable advancements, enhancing the precision and efficiency..."
        },
        {
            imgSrc: "/assets/images/blog/blog4.png",
            date: "Jun. 30, 2024",
            author: "Admin",
            title: "Silencing the Spin: Advanced Techniques for Gearbox Noise Reduction",
            description: "In the bustling world of machinery and mechanical systems, the symphony of moving parts often accompanies the..."
        },
        {
            imgSrc: "/assets/images/blog/blog2.png",
            date: "Jun. 30, 2024",
            author: "Admin",
            title: "Know the Advanced Materials in the Gear Manufacturing Sector",
            description: "In the dynamic domain of engineering, the quest for lighter yet stronger materials is perpetual. This pursuit has led to…"
        }
    ];

    return (
        <>
            <FaqBanner HeadingName={"Blog"} />
            <section className='blog'>
                <Container>
                    <Row>
                        <Col xxl={9} xl={8} lg={8} md={8}>
                            <div className='top-heading mb-4'>
                                <h2>Top Updates</h2>
                            </div>
                            <Row>
                                {blogData.map((blog, index) => (
                                    <Col xxl={4} xl={4} lg={6} md={6} sm={6} key={index}>
                                        <Link to={"/blog-details"}>
                                            <div className='update-card mb-5  '>
                                                <div className='update-img'>
                                                    <img className="blog-update-img" src={process.env.PUBLIC_URL + blog.imgSrc} alt={blog.title} />
                                                </div>
                                                <div className='heading-text'>
                                                    <h3>{blog.title}</h3>
                                                    <p>{blog.description}</p>
                                                    <span className="readmore-p"> Read More </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                        <Col xxl={3} xl={3} lg={4} md={4}>
                            <div className='top-heading mb-4'>
                                <h2>Recent Updates</h2>
                            </div>
                            {recentBlogs.map((blog, index) => (
                                <div className='recent-post-card mb-2' key={index}>
                                    <Row>
                                        <Col lg={7}>
                                            <div className='recent-img'>
                                                <img className="blog-recent-img" src={process.env.PUBLIC_URL + blog.imgSrc} alt={blog.title} />
                                            </div>
                                        </Col>
                                        <Col lg={5}>
                                            <div className='date-holder'>
                                                <div className='icon-holder'>
                                                    <FontAwesomeIcon icon={faCalendar} className='fa-icon' /><span>{blog.date}</span>
                                                </div>
                                                <div className='icon-holder'>
                                                    <FontAwesomeIcon icon={faUser} className='fa-icon' /><span>{blog.author}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <h3>{blog.title}</h3>
                                        <p>{blog.description}</p>
                                    </Row>
                                </div>
                            ))}
                        </Col>
                        <Col lg={12} className=''>
                            <div className=''>
                                <Pagination className=''>
                                    <Pagination.Prev  >
                                        <p>Prev</p>
                                    </Pagination.Prev>
                                    <Pagination.Item>{1}</Pagination.Item>
                                    <Pagination.Item>{2}</Pagination.Item>
                                    <Pagination.Item>{3}</Pagination.Item>
                                    <Pagination.Item>{4}</Pagination.Item>
                                    <Pagination.Next  >
                                        <p>Next</p>
                                    </Pagination.Next>
                                </Pagination>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Blog;
