import React from "react";
import "./App.css";
import "./index.css";

import { Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import About_Us from "./components/About_Us/About_Us";
import Faq from "./components/Faq/Faq";
import TermsCondition from "./components/TermsCondition/TermsCondition";
import PrivacyPolicy from "./components/TermsCondition/PrivacyPolicy";
import Blog from "./components/Blog/Blog";
import BlogDetails from "./components/Blog/BlogDetails/BlogDetails";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Gallery from "./components/Gallery/Gallery";
import Services from "./components/Services/Services";
import ExportServices from "./components/Services/ExportServices";
import QualityAssurance from "./components/QualityAssurance/QualityAssurance";
import ContactUs from "./components/Contact_Us/ContactUs";
import ServicesCategory from "./components/Services_category/ServicesCategory";
import ProductDetails from "./components/ProductDetails/ProductDetails";


function App() {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About_Us />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/services" element={<Services />} />
        <Route path="/export-services" element={<ExportServices />} />
        <Route path="/quality-assurance" element={<QualityAssurance />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/services-category" element={<ServicesCategory />} />
        <Route path="/product-details" element={<ProductDetails />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
