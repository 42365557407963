import React from 'react'
import FaqBanner from './FaqBanner/FaqBanner'
import { Container, Row } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import './faq.css'
import BreadcrumComponent from '../CommanComponent/BreadcrumComponent/BreadcrumComponent';
const Faq = () => {
    const faqData = [
        {
            eventKey: "0",
            header: "Why gear shaping?",
            body: (
                <>
                    <p> Gear shaping is a machining process used to manufacture gears with precision and efficiency. It involves cutting gear teeth into a workpiece using
                        a specialized cutting tool called a shaper cutter. Here’s why gear shaping is preferred:
                    </p>
                    <div className='d-flex'>
                        <span>Precision:</span>
                        <p>Gear shaping allows for the creation of precise gear teeth profiles with tight tolerances. This ensures smooth and efficient meshing of
                            gears, reducing wear and enhancing performance.
                        </p>
                    </div>
                    <div className='d-flex'>
                        <span>Versatility: </span>
                        <p> It can produce a wide range of gear types, including spur gears, helical gears, internal gears, and more, making it suitable for diverse
                            industrial applications.
                        </p>
                    </div>
                    <div className='d-flex'>
                        <span>Efficiency:  </span>
                        <p> The process is highly efficient in terms of material removal rate and tool life, making it cost-effective for manufacturing gears in large
                            quantities or as single prototypes.
                        </p>
                    </div>
                    <div className='d-flex'>
                        <span>Accuracy:  </span>
                        <p>  Gear shaping machines are capable of producing gears with consistent tooth profiles and accurate pitch dimensions, ensuring reliable
                            operation in machinery and equipment.
                        </p>
                    </div>
                    <div className='d-flex'>
                        <span>Complexity:  </span>
                        <p> It can handle complex gear designs & configurations, such as asymmetrical teeth or special tooth forms, meeting specific engineering
                            requirements.
                        </p>
                    </div>

                    <p> In summary, gear shaping is chosen for its ability to produce high-precision gears efficiently, ensuring reliability, durability, & performance in various
                        mechanical systems and industrial applications.
                    </p>
                </>
            )
        },
        {
            eventKey: "1",
            header: "What are the key factors that impact the gear?",
            body: (
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>
            )
        },
        {
            eventKey: "2",
            header: "What is hobbing?",
            body: (
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>
            )
        },
        {
            eventKey: "3",
            header: "Which method is utilized for gear production?",
            body: (
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>
            )
        },
        {
            eventKey: "4",
            header: "What are the different types of gears we provide?",
            body: (
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>
            )
        }
    ];
    const breadcrumbItems = [
        { text: "HOME", link: "/" },
        { text: "FAQs", link: "/faq", className: "active" },

    ];
    return (
        <>

            <FaqBanner HeadingName={"FAQs"} />
            <section className='faq-question'>
                <Container>
                    <Row>
                        <div className='mt-5'>
                            <BreadcrumComponent items={breadcrumbItems} />
                        </div>
                        <Accordion defaultActiveKey="0">
                            {faqData.map((faq, index) => (
                                <Accordion.Item eventKey={faq.eventKey} key={index}>
                                    <Accordion.Header>{faq.header}</Accordion.Header>
                                    <Accordion.Body>{faq.body}</Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>

                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Faq
