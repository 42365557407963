import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link if using React Router
import './CommanProductCard.css'
const CommanProductCard = ({ productName, image, detailsLink }) => {
    return (
        <Card className='mb-5'>
            <div className='product-icon text-center'>
                <Card.Img variant="top" src={image} className='product-img' />
            </div>
            <Card.Body>
                <Card.Title>
                    <h5 className='product-name'>{productName}</h5>
                </Card.Title>

            </Card.Body>
            <div className='overlayyy '>
                <Link className='get-details' to={detailsLink}>
                    View Details
                </Link>
            </div>

        </Card>
    );
}

export default CommanProductCard;
