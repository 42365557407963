import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './MeetSection.css'
import CommanButton from '../../CommanComponent/CommanButton/CommanButton'
import EnquiryModal from '../../CommanComponent/CommanModal/EnquiryModal/EnquiryModal'
const MeetSection = () => {
    const [modalEnquiry, setModalEnquiry] = React.useState(false);
    return (
        <>
           
                <section className='meet-section'>
                    <Container>
                        <Row>
                            <Col md={8}>
                                <div className='heading-holder'>
                                    <h2>Meet The </h2>
                                    <h3>L KAUMODAKIs</h3>
                                    <p>Our experts work seamlessly with our customers' team to support big ambitions,
                                        challenge preconceptions and amplify outcomes. <span>Let's advance together.</span></p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='d-flex'>
                                    <div className='button-holder text-md-end text-sm-center text-center'>
                                        <CommanButton className={"blue-btn"} text={"E-Brochure"} />
                                    </div>
                                    <div className='button-holder text-md-end text-sm-center text-center' onClick={() => setModalEnquiry(true)}>
                                        <CommanButton className={"blue-btn"} text={"Enquire"} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
         
            <EnquiryModal
                show={modalEnquiry}
                onHide={() => setModalEnquiry(false)}
            />
        </>
    )
}

export default MeetSection
