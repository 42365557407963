import React, { useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import "./ContactUs.css";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import CommanButton from "../CommanComponent/CommanButton/CommanButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import SuccessLottey from "../CommanComponent/SuccessLottey/SuccessLottey";
import { Link } from "react-router-dom";

library.add(fas);

const ContactUs = () => {
  const [phone, setPhone] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [animate, setAnimate] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const countryOptions = [
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
    { value: "three", label: "Three" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "#F3F3F3",
      borderRadius: "5px",
      fontSize: "14px",
      border: "none",
      height: "40px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "none",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d" /* Bootstrap's default placeholder color */,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#495057" /* Bootstrap's default input text color */,
    }),
  };

  const handleShowMap = () => {
    setAnimate("flip-horizontal-bottom");
    setShowMap(true);
    // setTimeout(() => {
    //   setShowMap(true);
    //   setAnimate("");
    // }, 500);
  };

  const handleShowForm = () => {
    setAnimate("flip-horizontal-top");
    setShowMap(false);
    // setTimeout(() => {
    //   setShowMap(false);
    //   setAnimate("");
    // }, 500);
  };

  const handleSubmit = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
      // Redirect to home page after 10 seconds
      window.location.href = "/";
    }, 3000); // 10 seconds
  };

  return (
    <section className="contact-us">
      <div className="bg-image"></div>

      <Container>
        <Row className="heading-row">
          <Col>
            <div className="heading-name">
              <h2>Contact Us</h2>
            </div>
          </Col>
        </Row>
        <Row className={`form-row  ${animate} `}>
          {!showMap ? (
            <>
              <Col lg={10} className="mx-auto">
                <Row>
                  <Col lg={8} md={6} className="pe-md-0 pe-sm-none ">
                    <div className="form-background">
                      <Form>
                        <Form.Group
                          className="mb-3"
                          controlId="formGroupFullName"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter Full Name*"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Control
                            type="email"
                            placeholder="Enter Email"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupPhone">
                          <PhoneInput
                            country={"in"}
                            enableSearch={true}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                            containerClass="phone-input-container" // Custom class for the container
                            inputClass="phone-input-field" // Custom class for the input field
                            buttonClass="phone-input-button" // Custom class for the button
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formGroupCountry"
                        >
                          <Select
                            options={countryOptions}
                            value={selectedCountry}
                            onChange={setSelectedCountry}
                            placeholder="Select Country*"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            styles={customStyles}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroupCity">
                          <Form.Control type="text" placeholder="Enter City*" />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formGroupMessage"
                        >
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Write your message here"
                            className="textarea-class"
                          />
                        </Form.Group>
                        {!showSuccessMessage && (
                          <div className="button-holder text-center my-md-5 my-sm-3 my-3">
                            <CommanButton
                              className={"blue-btn"}
                              text={"Submit"}
                              onClick={handleSubmit}
                            />
                          </div>
                        )}
                      </Form>
                      {showSuccessMessage && (
                        <div className="sent-message-text justify-content-center my-md-5 my-sm-3 my-3 d-flex">
                          <SuccessLottey />
                          <p>Message Sent Successfully</p>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={4} md={6} className="ps-md-0 ps-sm-none ps-none">
                    <div className="contact-details">
                      <div className="location-name">
                        <h3>Office</h3>
                      </div>
                      <div className="d-flex">
                        <div className="icon-holder me-3">
                          <FontAwesomeIcon
                            className="call-icon"
                            icon="fa-solid fa-location-dot"
                          />
                        </div>
                        <div className="address-holder">
                          <p>
                            Sr. No. 118, Tingare Nagar, Godown Chowk, Pune
                            Nashik Highway, Bhosari MIDC Pune 411039
                          </p>
                        </div>
                      </div>
                      <div className="d-flex mb-3">
                        <div className="icon-holder me-3">
                          <FontAwesomeIcon
                            className="call-icon"
                            icon="fa-solid fa-phone"
                          />
                        </div>
                        <div className="address-holder">
                          <p> +91 9762331812</p>
                        </div>
                      </div>
                      <div className="d-flex mb-3">
                        <div className="icon-holder me-3">
                          <FontAwesomeIcon
                            className="call-icon"
                            icon="fa-solid fa-envelope"
                          />
                        </div>
                        <div className="address-holder">
                          <p>info@lkaumodaki.com</p>
                        </div>
                      </div>
                      <div className="border-holder my-4"></div>
                      <div className="d-flex">
                        <div
                          className="address-holder map-text my-2"
                          onClick={handleShowMap}
                        >
                          <p>Find Us on Map</p>
                        </div>
                      </div>
                      <div className="d-flex social-icon">
                        <div className="border-icon text-center">
                          <Link
                            to={"https://www.facebook.com/"}
                            target="_black"
                          >
                            <FontAwesomeIcon
                              icon={faFacebookF}
                              className="fb-icon"
                            />
                          </Link>
                        </div>
                        <div className="border-icon text-center">
                          <Link to={"https://in.linkedin.com/"} target="_black">
                            <FontAwesomeIcon
                              icon={faLinkedinIn}
                              className="fb-icon"
                            />
                          </Link>
                        </div>
                        <div className="border-icon text-center">
                          <Link
                            to={"https://www.instagram.com/"}
                            target="_black"
                          >
                            <FontAwesomeIcon
                              icon={faInstagram}
                              className="fb-icon"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <Col lg={10} className="mx-auto">
              <div className={`map-section flip-horizontal-bottom ${animate}`}>
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      className="gmap_iframe"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    ></iframe>
                  </div>
                </div>
                <div className="close-button " onClick={handleShowForm}>
                  <img
                    className="icon-logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/close-circle.png"
                    }
                  />
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default ContactUs;
