import React, { useState } from "react";
import { Accordion, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CategoryTabSection.css";

const CategoryTabSection = ({ onSelect }) => {
  const [activeNavLink, setActiveNavLink] = useState("first");
  const [accordionActiveKey, setAccordionActiveKey] = useState(null);

  const handleCategoryClick = (eventKey) => {
    setActiveNavLink(eventKey);
    if (onSelect) {
      onSelect(eventKey);
    }
  };

  const handleAccordionClick = (eventKey) => {
    setAccordionActiveKey(accordionActiveKey === eventKey ? null : eventKey);
  };

  return (
    <>
      <div className="category-tab">
        <h3>CATEGORIES</h3>
        <Nav variant="pills" className="flex-column">
          <Nav.Item>
            <Nav.Link
              eventKey="first"
              active={activeNavLink === "first"}
              onClick={() => handleCategoryClick("first")}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-chevron-right"
                className="icon-fa"
              />{" "}
              Gears
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="second"
              active={activeNavLink === "second"}
              onClick={() => handleCategoryClick("second")}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-chevron-right"
                className="icon-fa"
              />{" "}
              Flanges
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="third"
              active={activeNavLink === "third"}
              onClick={() => handleCategoryClick("third")}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-chevron-right"
                className="icon-fa"
              />{" "}
              Jig Fixture
            </Nav.Link>
          </Nav.Item>

          <Accordion activeKey={accordionActiveKey}>
            <Accordion.Item eventKey="0">
              <Accordion.Header onClick={() => handleAccordionClick("0")}>
                <FontAwesomeIcon
                  icon="fa-solid fa-chevron-right"
                  className="icon-fa "
                />{" "}
                Components
              </Accordion.Header>
              <Accordion.Body>
                <Nav.Item>
                  <Nav.Link
                    eventKey="fourth"
                    active={activeNavLink === "fourth"}
                    onClick={() => handleCategoryClick("fourth")}
                    className="nested-link"
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-square"
                      className="icon-fa"
                    />{" "}
                    Brass Components
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="fifth"
                    active={activeNavLink === "fifth"}
                    onClick={() => handleCategoryClick("fifth")}
                    className="nested-link"
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-square"
                      className="icon-fa"
                    />{" "}
                    Stainless Steel Components
                  </Nav.Link>
                </Nav.Item>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Nav.Item>
            <Nav.Link
              eventKey="seventh"
              active={activeNavLink === "seventh"}
              onClick={() => handleCategoryClick("seventh")}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-chevron-right"
                className="icon-fa"
              />{" "}
              Mechanical Job
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="eighth"
              active={activeNavLink === "eighth"}
              onClick={() => handleCategoryClick("eighth")}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-chevron-right"
                className="icon-fa"
              />{" "}
              Precision Tool Room Work
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <div className="category-tab">
        <h3>CONTACT US</h3>

        <div className="d-flex mb-3">
          <div className="icon-holder ">
            <FontAwesomeIcon className="call-icon" icon="fa-solid fa-phone" />
          </div>
          <div className="address-holder">
            <p>+91 9762331812</p>
          </div>
        </div>
        <div className="d-flex mb-3">
          <div className="icon-holder ">
            <FontAwesomeIcon
              className="call-icon"
              icon="fa-solid fa-envelope"
            />
          </div>
          <div className="address-holder">
            <p>info@lkaumodaki.com</p>
          </div>
        </div>
        <div className="d-flex">
          <div className="icon-holder">
            <FontAwesomeIcon
              className="call-icon"
              icon="fa-solid fa-location-dot"
            />
          </div>
          <div className="address-holder">
            <p>
              Sr. No. 118, Tingare Nagar, Godown Chowk, Pune Nashik Highway,
              Bhosari MIDC Pune 411039
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryTabSection;
