import React from 'react'
import ServicesBannerfirst from './ServicesBannerfirst/ServicesBannerfirst'
import Servicestab from './Servicestab/Servicestab'

const Services = () => {
    return (
        <>
            <ServicesBannerfirst />
            <Servicestab />
        </>
    )
}

export default Services
