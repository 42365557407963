import React from 'react'
import './Gallery.css'
import { Col, Container, Row } from 'react-bootstrap'
import FaqBanner from '../Faq/FaqBanner/FaqBanner'
import BreadcrumComponent from '../CommanComponent/BreadcrumComponent/BreadcrumComponent'
import Pagination from 'react-bootstrap/Pagination';
import GallerImageModal from '../CommanComponent/CommanModal/GallerImageModal/GallerImageModal'

const Gallery = () => {
    const [modalGallery, setModalGallery] = React.useState(false);
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
    const breadcrumbItems = [
        { text: "HOME", link: "/" },
        { text: "GALLERY", link: "/gallery", className: "active" },
    ];

    const galleryData = [
        { imgSrc: "/assets/images/gallery/gallery3.png" },
        { imgSrc: "/assets/images/gallery/gallery1.png" },
        { imgSrc: "/assets/images/gallery/gallery1.png" },
        { imgSrc: "/assets/images/gallery/gallery4.png" },
        { imgSrc: "/assets/images/gallery/gallery2.png" },
        { imgSrc: "/assets/images/gallery/gallery2.png" },
        { imgSrc: "/assets/images/gallery/gallery3.png" },
        { imgSrc: "/assets/images/gallery/gallery1.png" },
        { imgSrc: "/assets/images/gallery/gallery1.png" },
        { imgSrc: "/assets/images/gallery/gallery4.png" },
        { imgSrc: "/assets/images/gallery/gallery2.png" },
        { imgSrc: "/assets/images/gallery/gallery2.png" },
    ];

    const openModal = (index) => {
        setCurrentImageIndex(index);
        setModalGallery(true);
    };

    return (
        <>
            <FaqBanner HeadingName={"Gallery"} />
            <section className='gallery'>
                <Container>
                    <Row>
                        <div className='my-5'>
                            <BreadcrumComponent items={breadcrumbItems} />
                        </div>
                        {galleryData.map((item, index) => (
                            <Col lg={4} md={6} key={index}>
                                <div className='gallery-image mb-4' onClick={() => openModal(index)}>
                                    <img className="all-galley-img" src={process.env.PUBLIC_URL + item.imgSrc} alt="all-galley-img" />
                                </div>
                            </Col>
                        ))}
                        <Col lg={12} className='blog'>
                            <div className=''>
                                <Pagination className=''>
                                    <Pagination.Prev><p>Prev</p></Pagination.Prev>
                                    <Pagination.Item>{1}</Pagination.Item>
                                    <Pagination.Item>{2}</Pagination.Item>
                                    <Pagination.Item>{3}</Pagination.Item>
                                    <Pagination.Item>{4}</Pagination.Item>
                                    <Pagination.Next><p>Next</p></Pagination.Next>
                                </Pagination>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <GallerImageModal
                show={modalGallery}
                onHide={() => setModalGallery(false)}
                galleryData={galleryData}
                currentImageIndex={currentImageIndex}
                setCurrentImageIndex={setCurrentImageIndex}
            />
        </>
    )
}

export default Gallery;
