import React from "react";
import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const ExportServicesTab = () => {
  return (
    <>
      <section className="serives-tabs">
        <Container>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col lg={3} md={5} sm={12} xs={12} className="mx-auto">
                <Nav variant="pills" className="justify-content-center">
                  <Nav.Item>
                    <NavLink
                      to="/services"
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                    >
                      Services
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      to="/export-services"
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                    >
                      Export Import
                    </NavLink>
                  </Nav.Item>
                </Nav>
              </Col>

              <div className="text-holder">
                <h2>
                  L Koumodaki Mfg. specializes in exporting gears & other
                  mechanical components, focusing particularly in Gulf countries
                  and Europe. This indicates they likely play a crucial role in
                  the supply chain for industrial machinery & equipment in these
                  regions.
                </h2>
              </div>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </>
  );
};

export default ExportServicesTab;
