import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './WHyShouldCard.css';

const WHyShouldCard = ({ className, HeadingName, image, SecondText, ParagraphText }) => {
    return (

        <div className={`why-should-card   ${className}`}>
            <Row>
                <Col md={8} sm={8} xs={8}>
                    <div className='heading-holder'>
                        <h2>{HeadingName}</h2>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={4}>
                    <div className='icon-holder text-end'>
                        <img className="queta-img" src={image} alt="queta-img" />
                    </div>
                </Col>
                <div className='text-holder'>
                    <h3>{SecondText}</h3>
                    <p>{ParagraphText}</p>
                </div>
            </Row>
        </div>

    );
}

export default WHyShouldCard;
