import React from 'react'
import CommanBanner from '../../CommanComponent/CommanBanner/CommanBanner'
const QualityBanner = () => {
    return (
        <>
            <section className="About-banner">
                <div>
                    <CommanBanner
                        VideoPath={process.env.PUBLIC_URL + "/assets/video/home-video.mp4"}
                        imgClassName="d-none"
                        thirdHeading={"Quality Assurance"}
                        SecondHeading="Engineered for Excellence, Assured for Performance"
                        buttonName="d-none"
                    />
                </div>
            </section>
        </>
    )
}

export default QualityBanner
