import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './TechnologyCard.css'

const TechnologyCard = ({ image, imgClassName, textClassName, content, ParagraphText }) => {

    return (
        <>
            <div className='technology-card mb-5'>
                <Row>
                    <Col md={3} sm={3} xs={3}>
                        <div className=''>
                            <img src={image} className="icon-img" alt="icon" />
                        </div>
                    </Col>
                    <Col md={9} sm={9} xs={9}>
                        <div className="text-holder ">
                            <h2 className={textClassName}>{content}</h2>
                            <div className='border-line my-2'></div>
                            <p>{ParagraphText}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default TechnologyCard
