import React from 'react';
import './LogoSlider.css';
import { Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const LogoSlider = ({ headingName }) => {
    const logoSlider = [
        {
            image: process.env.PUBLIC_URL + "/assets/images/home/mahindra_logo-1.png",
            image2: process.env.PUBLIC_URL + "/assets/images/home/mahindra.png"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/home/tata-Motor.png",
            image2: process.env.PUBLIC_URL + "/assets/images/home/tata-2.png"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/home/BHARAT-FORGE-gray.png",
            image2: process.env.PUBLIC_URL + "/assets/images/home/bharat-forge-blu.png"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/home/Component-1.png",
            image2: process.env.PUBLIC_URL + "/assets/images/home/jcb-2.png"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/home/mahindra_logo-1.png",
            image2: process.env.PUBLIC_URL + "/assets/images/home/mahindra.png"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/home/tata-Motor.png",
            image2: process.env.PUBLIC_URL + "/assets/images/home/tata-2.png"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/home/BHARAT-FORGE-gray.png",
            image2: process.env.PUBLIC_URL + "/assets/images/home/bharat-forge-blu.png"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/home/Component-1.png",
            image2: process.env.PUBLIC_URL + "/assets/images/home/jcb-2.png"
        },
    ];

    return (
        <section className='logo-slider'>
            <Container>
                <Row>
                    <div className='heading-holder text-center mb-md-4 mb-sm-2 mb-2'>
                        <h2>{headingName}</h2>
                    </div>
                    <Swiper
                        breakpoints={{
                            0: { slidesPerView: 1, spaceBetween: 10 },
                            486: { slidesPerView: 2, spaceBetween: 20 },
                            768: { slidesPerView: 2, spaceBetween: 20 },
                            992: { slidesPerView: 2, spaceBetween: 20 },
                            1200: { slidesPerView: 3, spaceBetween: 30 },
                            1400: { slidesPerView: 4, spaceBetween: 30 },
                            1900: { slidesPerView: 4, spaceBetween: 30 },
                        }}
                        pagination={{ dynamicBullets: true }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {logoSlider.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className='mb-md-5 mb-sm-3 mb-3'>
                                    <div className='sponsers-logo'>
                                        <div className='first-image'>
                                            <img className="logo-company" src={item.image} alt="Logo" />
                                        </div>
                                        <div className='second-image'>
                                            <img className="logo-company2" src={item.image2} alt="Logo" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Row>
            </Container>
        </section>
    );
};

export default LogoSlider;
