import React from "react";
import "./Manufacturing_Unit_Card.css";

function Manufacturing_Unit_Card({ imgSrc, title, description }) {
  return (
    <>
      <section className="Manufacturing_Unit_Card">
        <div className="Manufacturing_Unit_Card_holder">
          <div className="img-holder">
            <img
              className="Manufacturing_Unit_Card_img"
              src={imgSrc}
              alt="Manufacturing_Unit_img"
            />
          </div>

          <div className="current-hover"></div>

          <div className="text-holder">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Manufacturing_Unit_Card;
