import React from 'react'
import './FaqBanner.css'
const FaqBanner = ({HeadingName}) => {
  return (
    <>
      <section className='faq-banner'>
        <div className='heading-holder text-center'>
            <h1>{HeadingName}</h1>
        </div>
      </section>
    </>
  )
}

export default FaqBanner
